@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/theme.scss";

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
  .rtl {
    direction: rtl;
  }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
  display: none;
}

// AOS styles
$aos-distance: 10px;

// @import "node_modules/aos/src/sass/aos.scss";
// @import 'node_modules/react-calendar/dist/Calendar.css';
@import "additional-styles/Global.scss";
@import "additional-styles/SignIn.scss";
@import "additional-styles/Popup.scss";
@import "additional-styles/Intro.scss";
@import "additional-styles/InformationDesk.scss";
@import "additional-styles/Language.scss";
@import "additional-styles/Authentication.scss";
@import "additional-styles/Exhibition.scss";
@import "additional-styles/ExhibitionMain.scss";
@import "additional-styles/VirtualTour.scss";
@import "additional-styles/ExpoLounge.scss";
@import "additional-styles/Sessions.scss";
@import "additional-styles/MeetingRoom.scss";
@import "additional-styles/ExhibitionFloor.scss";
@import "additional-styles/Booth.scss";
@import "additional-styles/Game.scss";
@import "additional-styles/Coin.scss";
@import "additional-styles/360.scss";