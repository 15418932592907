$coin-diameter: 38px;
$coin-thickness: 5px;
$coin-color: #ffe178;
$edge-faces: 26;
$edge-face-length: 3.14 * $coin-diameter/$edge-faces;
$turn-time: 2.5s;

.coin {
  position: relative;
  width: $coin-diameter;
  height: $coin-diameter;
  transform-style: preserve-3d;
  animation: rotate3d $turn-time linear infinite;
  transition: all 0.3s;
  user-select: none;


  .coin__front,
  .coin__back {
    position: absolute;
    width: $coin-diameter;
    height: $coin-diameter;
    border-radius: 50%;
    overflow: hidden;
    background-color: $coin-color;

    &:after {
      content: "";
      position: absolute;
      left: -$coin-diameter/2;
      bottom: 100%;
      display: block;
      height: $coin-diameter/1.5;
      width: $coin-diameter * 2;
      background: #fff;
      opacity: 0.3;
      animation: shine linear $turn-time/2 infinite;
    }
  }

  .coin__front {
    transform: translateZ($coin-thickness/2);
  }

  .coin__back {
    transform: translateZ(-$coin-thickness/2) rotateY(180deg);
  }

  .coin__edge {
    @for $i from 1 through $edge-faces {
      div:nth-child(#{$i}) {
        position: absolute;
        height: $edge-face-length;
        width: $coin-thickness;
        background: darken($coin-color,
            (($i - $edge-faces/2) * ($i - $edge-faces/2)) / ($edge-faces * $edge-faces/4) * 100 * 0.7);
        transform: translateY(#{$coin-diameter/2-$edge-face-length/2}) translateX(#{$coin-diameter/2-$coin-thickness/2}) rotateZ(360deg / $edge-faces * $i + 90) translateX(#{$coin-diameter/2}) rotateY(90deg);
      }
    }
  }

  .coin__shadow {
    display: none;
    position: absolute;
    width: $coin-diameter;
    height: $coin-thickness;
    border-radius: 50%;
    background: #000;
    box-shadow: 0 0 $coin-thickness * 5 $coin-thickness * 5 #000;
    opacity: 0.125;
    transform: rotateX(90deg) translateZ(-$coin-diameter * 1.1) scale(0.5);
  }

  @keyframes rotate3d {
    0% {
      transform: perspective(1000px) rotateY(0deg);
    }

    100% {
      transform: perspective(1000px) rotateY(360deg);
    }
  }

  @keyframes shine {

    0%,
    15% {
      transform: translateY($coin-diameter * 2) rotate(-40deg);
    }

    50% {
      transform: translateY(-$coin-diameter) rotate(-40deg);
    }
  }
}

$coin-diameter: 28px;
$coin-thickness: 3px;
$coin-color: #ffe178;
$edge-faces: 26;
$edge-face-length: 3.14 * $coin-diameter/$edge-faces;
$turn-time: 2s;

.coin.coin-sm {
  position: relative;
  width: $coin-diameter;
  height: $coin-diameter;
  transform-style: preserve-3d;
  animation: rotate3d $turn-time linear infinite;
  transition: all 0.3s;

  .coin__front,
  .coin__back {
    position: absolute;
    width: $coin-diameter;
    height: $coin-diameter;
    border-radius: 50%;
    overflow: hidden;
    background-color: $coin-color;

    &:after {
      content: "";
      position: absolute;
      left: -$coin-diameter/2;
      bottom: 100%;
      display: block;
      height: $coin-diameter/1.5;
      width: $coin-diameter * 2;
      background: #fff;
      opacity: 0.3;
      animation: shine linear $turn-time/2 infinite;
    }
  }

  .coin__front {
    transform: translateZ($coin-thickness/2);
  }

  .coin__back {
    transform: translateZ(-$coin-thickness/2) rotateY(180deg);
  }

  .coin__edge {
    @for $i from 1 through $edge-faces {
      div:nth-child(#{$i}) {
        position: absolute;
        height: $edge-face-length;
        width: $coin-thickness;
        background: darken($coin-color,
            (($i - $edge-faces/2) * ($i - $edge-faces/2)) / ($edge-faces * $edge-faces/4) * 100 * 0.7);
        transform: translateY(#{$coin-diameter/2-$edge-face-length/2}) translateX(#{$coin-diameter/2-$coin-thickness/2}) rotateZ(360deg / $edge-faces * $i + 90) translateX(#{$coin-diameter/2}) rotateY(90deg);
      }
    }
  }

  .coin__shadow {
    display: none;
    position: absolute;
    width: $coin-diameter;
    height: $coin-thickness;
    border-radius: 50%;
    background: #000;
    box-shadow: 0 0 $coin-thickness * 5 $coin-thickness * 5 #000;
    opacity: 0.125;
    transform: rotateX(90deg) translateZ(-$coin-diameter * 1.1) scale(0.5);
  }

  @keyframes rotate3d {
    0% {
      transform: perspective(1000px) rotateY(0deg);
    }

    100% {
      transform: perspective(1000px) rotateY(360deg);
    }
  }

  @keyframes shine {

    0%,
    15% {
      transform: translateY($coin-diameter * 2) rotate(-40deg);
    }

    50% {
      transform: translateY(-$coin-diameter) rotate(-40deg);
    }
  }
}

$coin-diameter: 18px;
$coin-thickness: 2px;
$coin-color: #ffe178;
$edge-faces: 26;
$edge-face-length: 3.14 * $coin-diameter/$edge-faces;
$turn-time: 3s;

.coin.coin-xs {
  position: relative;
  width: $coin-diameter;
  height: $coin-diameter;
  transform-style: preserve-3d;
  animation: rotate3d $turn-time linear infinite;
  transition: all 0.3s;

  .coin__front,
  .coin__back {
    position: absolute;
    width: $coin-diameter;
    height: $coin-diameter;
    border-radius: 50%;
    overflow: hidden;
    background-color: $coin-color;

    &:after {
      content: "";
      position: absolute;
      left: -$coin-diameter/2;
      bottom: 100%;
      display: block;
      height: $coin-diameter/1.5;
      width: $coin-diameter * 2;
      background: #fff;
      opacity: 0.3;
      animation: shine linear $turn-time/2 infinite;
    }
  }

  .coin__front {
    transform: translateZ($coin-thickness/2);
  }

  .coin__back {
    transform: translateZ(-$coin-thickness/2) rotateY(180deg);
  }

  .coin__edge {
    @for $i from 1 through $edge-faces {
      div:nth-child(#{$i}) {
        position: absolute;
        height: $edge-face-length;
        width: $coin-thickness;
        background: darken($coin-color,
            (($i - $edge-faces/2) * ($i - $edge-faces/2)) / ($edge-faces * $edge-faces/4) * 100 * 0.7);
        transform: translateY(#{$coin-diameter/2-$edge-face-length/2}) translateX(#{$coin-diameter/2-$coin-thickness/2}) rotateZ(360deg / $edge-faces * $i + 90) translateX(#{$coin-diameter/2}) rotateY(90deg);
      }
    }
  }

  .coin__shadow {
    display: none;
    position: absolute;
    width: $coin-diameter;
    height: $coin-thickness;
    border-radius: 50%;
    background: #000;
    box-shadow: 0 0 $coin-thickness * 5 $coin-thickness * 5 #000;
    opacity: 0.125;
    transform: rotateX(90deg) translateZ(-$coin-diameter * 1.1) scale(0.5);
  }

  @keyframes rotate3d {
    0% {
      transform: perspective(1000px) rotateY(0deg);
    }

    100% {
      transform: perspective(1000px) rotateY(360deg);
    }
  }

  @keyframes shine {

    0%,
    15% {
      transform: translateY($coin-diameter * 2) rotate(-40deg);
    }

    50% {
      transform: translateY(-$coin-diameter) rotate(-40deg);
    }
  }
}