.menu {
  margin: 60px 0 0 0;

  li {
    a,
    span {
      cursor: pointer;
      padding: 15px 25px;
      display: block;
      line-height: 15px;
      color: #fff;
      transition: all 0.5s;
      transform: translateX(0);

      &:hover {
        transform: translateX(5px);
        opacity: 0.7;
      }
    }

    ul {
      margin-left: 40px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      li {
        a,
        span {
          padding: 8px 0px;
          border-top: 1px solid rgba(255, 255, 255, 0.2);
          font-weight: 300;
          font-size: 14px;
          background: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  background: #000;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  z-index: 4;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.hidden {
    display: none;
  }
}

.overlay > nav {
  position: relative;
  height: 90%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.exhibitionPage {
  background: #000;
}

.button_container {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  z-index: 5;
  background: url(../../images/nav.png) no-repeat 5px -35px rgba(0, 0, 0, 0.5);
  background-size: 40px;
  white-space: nowrap;
  -webkit-transition: opacity 0.25s ease, max-height 0.25s ease;
  transition: opacity 0.25s ease, max-height 0.25s ease;
  cursor: pointer;
  outline: none;
}

.button_container.active {
  background: url(../../images/nav.png) no-repeat 5px -35px rgba(0, 0, 0, 0.5);
  background-size: 40px;
}

.button_container span {
  position: absolute;
  background-color: #fff;
  width: 30px;
  height: 2px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

.button_container span:before {
  content: "";
  position: absolute;
  top: -6px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  -webkit-transition: -webkit-transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.button_container span:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  -webkit-transition: -webkit-transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.modelAttendee,
.modelContact {
  .close {
    top: 15px;
    right: 15px;
  }

  .popup-header {
    h3 {
      font-size: 18px;
      color: #000;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      font-weight: 600;
    }
  }

  .popup-body {
    .filter {
      display: flex;
      color: #000;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;

      .form-control {
        outline: none;
        appearance: none;
        -webkit-appearance: none;
        margin-left: 10px;
        border: 0 !important;
        border-radius: 2px;
        line-height: 12px;
        color: #000;
        font-size: 12px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: 95% center;
        background-size: 5%;
        position: relative;
        font-weight: 500;

        width: 100%;
        background-image: none;
        padding-right: 30px;
        border: 1px solid #efefef !important;
        padding-left: 10px;
        margin: 0 10px;

        &:focus {
          outline: none;
          box-shadow: none;
          border-color: transparent;
        }
      }
    }

    .users_data {
      .user-profile-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        margin: 0;
        padding: 8px;

        &:nth-child(even) {
          background-color: #f2f4f7;
        }

        .user-profile-data {
          width: 85%;
          display: flex;
          align-items: center;

          .user-profile-image {
            width: 32px;
            float: left;
            height: 32px;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f1f1f1;
            border: 1px solid #fff;
            margin-right: 10px;

            img {
              border-radius: 0%;
              width: 100%;
            }
          }

          .user-profile-info {
            max-width: 70%;
            float: left;

            .user-profile-name {
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 0px;
              line-height: 15px;
              color: #000;
              text-transform: capitalize;

              a {
                color: #000;
              }
            }

            .user-profile-company {
              font-size: 12px;
              font-weight: 400;
              line-height: 12px;
              color: #000;
              margin-bottom: 0px;
            }
          }
        }

        .user-profile-more {
          min-width: 40px;
          text-align: center;

          i {
            font-size: 16px;
          }

          img {
            width: 20px;
          }
        }
      }
    }
  }
}

.modelMyBag {
  .close {
    top: 15px;
    right: 15px;
  }

  .popup-header {
    h3 {
      font-size: 18px;
      color: #000;
      padding-bottom: 10px;
      font-weight: 600;
    }
  }

  .popup-body {
    .ul-bag {
      margin-bottom: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 0;
        border-bottom: 1px solid #ecebeb;

        .icon-bag-fr,
        .icons-bag-right {
          display: flex;

          img {
            width: 17px;
            height: 17px;
            margin: 0px 5px;
          }
        }

        .text-bag-inside {
          color: #000;
        }
      }
    }

    .tabContainer {
      border: 1px solid #eceaea !important;

      .tabLabel {
        .react-tabs__tab-list {
          margin-bottom: 0;

          .react-tabs__tab {
            color: #000;
            border-radius: 0;
            cursor: pointer;

            &.react-tabs__tab--selected {
              background-color: var(--primary);
              border-color: var(--primary);
              color: #fff;
            }
          }
        }
      }

      .tab-progress {
        padding: 10px;
        border-top: 1px solid var(--primary) !important;

        .react-tabs__tab-panel {
          color: #665b5b;
        }
      }
    }

    .profileSec {
      max-height: 75vh;
    }
  }
}

.sliderExhibitors {
  padding: 0 50px;

  .slick-arrow {
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      font-size: 40px;
      color: red;
      margin-bottom: -5px;
      display: block;
    }

    color: #fff;

    &.slick-prev {
      left: -50px;
    }

    &.slick-next {
      right: -50px;
    }
  }

  .logoSlide {
    padding: 50px 20px;
  }
}

.my-contact-title {
  margin-bottom: 20px;
}

.modelLiveChat {
  .personalChat {
    width: calc(100% - 230px);
  }
}

.my-contact-section {
  background: #f5f5f5;
  padding: 15px 0 0;
  color: #515c6f;
  width: 230px;
  min-width: 230px;

  .title-sm {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .my-contacts-online {
    padding-left: 15px;
    padding-right: 15px;
    max-height: 380px;
    overflow-y: auto;

    li {
      font-size: 13px;
      background: #fff;
      padding: 5px 16px;
      margin-bottom: 6px;
      border-radius: 14px;
      text-transform: capitalize;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: #f9cfd3;
        color: #000;
      }

      &.active {
        background-color: var(--primary);
        color: #fff;
      }
    }
  }
}

.coinPopup {
  position: fixed;
  background: #000000d3;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  z-index: 5;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  &.hidden {
    display: none;
  }

  .coinModal {
    width: 500px;
    background: transparent linear-gradient(269deg, #e13fc0 0%, #7008f8 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;

    @media (max-width: 820px) {
      width: 340px;
    }

    .coinModalHeader {
      display: flex;
      align-items: center;
      padding: 20px;
      position: relative;

      @media (max-width: 820px) {
        padding: 10px 15px;
      }

      h3 {
        font-size: 1.428571428571429rem;
        font-weight: bold;
        color: #fff;

        @media (max-width: 820px) {
          font-size: 1.142857142857143rem;
        }
      }

      .coinImg {
        position: absolute;
        right: 15px;
        bottom: 15px;
        -moz-animation: bounce 0.9s infinite linear;
        -o-animation: bounce 0.9s infinite linear;
        -webkit-animation: bounce 0.9s infinite linear;
        animation: bounce 0.9s infinite linear;

        @media (max-width: 820px) {
          width: 50px;
          height: 50px;
        }
      }
    }

    .coinModalBody {
      background-color: #fff;
      overflow: hidden;
      border-radius: 10px;

      .coinCountCenter {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;

        @media (max-width: 820px) {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }

      .coinModalFooter {
        padding: 15px;

        .earnViewText {
          h5 {
            font-size: 18px;
            font-weight: bold;
            color: #000;

            @media (max-width: 820px) {
              font-size: 15px;
            }
          }

          span {
            font-size: 15px;
            color: #000;

            @media (max-width: 820px) {
              font-size: 12px;
            }
          }
        }

        .earnViewBtn {
          padding-left: 20px;

          @media (max-width: 820px) {
            padding-left: 10px;
          }

          a {
            background: #630ccb;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            padding: 10px 30px;
            border-radius: 30px;
            white-space: nowrap;

            @media (max-width: 820px) {
              padding: 5px 15px;
            }
          }
        }
      }
    }
  }
}

.react-joyride__tooltip {
  background-image: url(../../images/man.png);
  background-position: left 15px;
  background-repeat: no-repeat;
  width: 420px !important;
}

.stepSection_blk {
  padding-left: 125px;
  text-align: left;

  h5 {
    font-size: 18px;
    line-height: 1;
    letter-spacing: 0.18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 0.15px;
    display: flex;
    flex-wrap: wrap;
  }
}
.ticker {
  bottom: 30px;
  font-size: large;
  z-index: 1;
  left: 0px;
  background: #601a20;
  .ticker__element {
    color: #fff;
    background: #601a20;
    padding: 3px 15px;
    display: flex;
    border-radius: 15px;
  }
}
