.boothPage {
    .btn-back {
        position: absolute;
        right: 100px;
        top: 30px;

    }

    
    .boothImg {
        height: 100vh;
        width: 100vw;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: #9c9f98;

        video {
            height: 100vh;
            width: 100vw;
            object-fit: cover;
        }

        img {
            width: 1940px;
            max-width: unset;

            @media (max-width:1250px) {
                width: 1280px;
            }

            @media (max-height:420px) {
                width: 890px;
            }
        }
    }

    .videoFrame {
        height: 108vh;
        max-width: unset;
        height: 1000px; 
    }
    .boothContainer {
        position: absolute;
        width: 1300px;
        height: 700px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (width:1920px) and (min-height:1075px) {
            width: 122vh;
            height: 64vh;
        }

        @media (width:1680px) and (min-height:940px) {
            width: 122vh;
            height: 64vh;
        }


        @media (max-width:1600px) and (min-height:910px) {
            width: 122vh;
            height: 64vh;
        }

        @media (max-width:1600px) and (max-height:909px) {
            width: 1117px;
            height: 580px;
            transform: translateY(-3px);
        }

        @media (max-width:1440px) and (min-height:810px) {
            width: 122vh;
            height: 64vh;
        }

        @media (max-width:1440px) and (max-height:809px) {
            width: 1000px;
            height: 510px;
            transform: translateY(-8px);
        }

        @media (max-width:1400px) and (min-height:785px) {
            width: 122vh;
            height: 64vh;
            transform: translateY(-8px);
        }

        @media (max-width:1400px) and (max-height:784px) {
            width: 1000px;
            height: 510px;
            transform: translateY(-8px);
        }

        @media (max-width:1366px) {
            width: 910px;
            height: 490px;
        }


        @media (max-width:1366px) and (min-height:768px) {
            width: 122vh;
            height: 64vh;
        }

        @media (max-width:1366px) and (max-height:768px) {
            width: 882px;
            height: 490px;
            transform: translateY(-8px);
        }

        @media (max-width:1280px) and (min-height:719px) {
            width: 122vh;
            height: 64vh;
        }

        @media (max-width:1280px) and (max-height:718px) {
            width: 850px;
            height: 466px;
            transform: translateY(-6px);
        }

        @media (max-width:1240px) and (min-height:680px) {
            width: 122vh;
            height: 64vh;
        }

        @media (max-width:1240px) and (max-height:679px) {
            width: 800px;
            height: 450px;
        }

        @media (max-width:1040px) {
            width: 900px;
            height: 505px;
            transform: translateY(-5px);
        }

        
        
        
        

        .boothHint {
            display: flex;
            justify-content: center;

            &:before {
                display: none;
                background: #2b2c2d;
                padding: 4px 10px;
                font-size: 13px;
                border: 1px solid #dedede;
                border-radius: 4px;
                color: #fff;
                text-align: center;
                content: attr(rel);
                position: absolute;
                width: auto;
                top: 30px;
                white-space: nowrap;
            }

            &:hover {
                &:before {
                    display: block;
                }
            }
        }

        &.boothright {

            .companyvideo {
                position: absolute;
                max-width: 18%;
                height: 19%;
                top: 19%;
                left: 21%;

                @media (max-width:1024px) {
                    top: 21%;
                    left: 20%;
                }
            }

            .boothHint {
                position: absolute;
                z-index: 3;
                cursor: pointer;

                &.salesInfo {
                    left: 71%;
                    top: 69%;
                }

                &.videoInfo {
                    top: 15%;
                    left: 28%;
                }

                &.brochures {
                    top: 70%;
                    left: 49%;
                }
            }

        }

        &.boothleft {

            .companyvideo {
                position: absolute;
                max-width: 18%;
                height: 19%;
                top: 19%;
                right: 21%;

                @media (max-width:1024px) {
                    top: 21%;
                    left: 20%;
                }
            }

            .boothHint {
                position: absolute;
                z-index: 3;
                cursor: pointer;

                &.salesInfo {
                    right: 71%;
                    top: 69%;
                    left: unset;
                }

                &.videoInfo {
                    top: 15%;
                    right: 28%;
                    left: unset;
                }

                &.brochures {
                    top: 70%;
                    left: 49%;
                }
            }

        }

        &.boothcenter {
            .companyvideo {
                position: absolute;
                max-width: 18%;
                height: 19%;
                top: 19%;
                left: 41%;

                @media (max-width:1024px) {
                    top: 21%;
                    left: 20%;
                }
            }

            .boothHint {
                position: absolute;
                z-index: 3;
                cursor: pointer;

                &.salesInfo {
                    right: 46%;
                    top: 68%;
                    left: unset;
                }

                &.videoInfo {
                    top: 15%;
                    right: 50%;
                    left: unset;
                }

                &.brochures {
                    top: 68%;
                    left: 68%;
                }
            }

        }
    }
}


.contactInfo {
    width: 100%;
    height: 200px;

    h4,
    h2 {
        margin-bottom: 1.07rem;
    }

    .col-sm-6 {
        float: left;
        width: 50%;

        p {
            font-size: 1.07rem;
        }

        h3 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 1.07rem;
        }
    }
}

.brochuresModel {
    .popup-body {
        max-height: 800px;

        iframe {
            height: 500px;
            max-height: 75vh;
        }
    }
}

.boothvideoModel {
    .boothvideo {
        height: 450px;
        max-height: 75vh;

        video {
            height: 100%;
        }
    }
}