.virtualTourPage {
  background-image: url("../../images/virtual.png");
  background-color: #373737;
}

.vrvideolist {
  position: relative;
  max-width: 1831px;
  margin: 0 auto;
  left: 0;
  right: 0;
  // top: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .videothumb {
    width: 430px;
    padding: 15px;
    background: #000;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 300px;
    color: #fff;
    font-size: 25px;
    border-radius: 20px;
    cursor: pointer;
    &.videothumb_ru {
      font-size: 18px;
    }

    &:hover {
      background: #0000008f;
    }

    span {
      margin-top: 20px;
    }

    iframe {
      height: 205px;
      width: 100%;
    }
  }
}
