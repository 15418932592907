.modelpopInformation {
  .popInformation {
    padding-left: 170px;
    position: relative;
    background-image: url(../../images/man.png);
    background-repeat: no-repeat;
  }
}

.modelMyProfile {
  .popup-body {
    overflow-y: visible;
  }
}

.modalCertificate {
  .dialogBox {
    max-width: 86vh;
  }

  .popup-body {
    max-height: unset;

    .certificateImg {
      img {
        width: 86vh;
        margin: 0 auto;
      }
    }
  }
}

.modalRedeem {
  &.coinPopup {
    .coinOverlay {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }

    .coinModal {
      width: 100%;
      max-width: 1020px;
      z-index: 2;
      position: relative;

      .coinModalHeader {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .coinModalBody {
        .redeemrange {
          position: absolute;
          background: #fff;
          top: 10px;
          width: 90%;
          display: flex;
          align-items: center;
          left: 5%;
          padding: 5px;
          border-radius: 20px;
          .range {
            width: 100%;
            text-align: center;
            line-height: 1;
            display: block;
            font-size: 1.1rem;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            @media (max-width: 991px) {
              font-size: 1rem;
            }
          }
        }
        .coinModalFooter {
          padding-top: 0;

          .earnViewBtn {
            margin-left: 0;
          }
        }
      }
    }
  }
}
