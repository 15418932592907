
.authenticationSec {
    position: absolute;
    z-index: 1;
    width: 390px;
    height: 100%;
    background: #902630;
    border-right: 15px solid #A42A39;
    border-left: 15px solid #A42A39;
    right: 0;
}
.detailsLogo{
    img{
        background-color: #fff;
        padding: 10px;
        
    }
}