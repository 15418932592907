.view360Model {
    .customPopContainer {
        .popup-header h3 {
            padding-bottom: 30px;
            margin-top: 10px;
        }

        .popup-body {
            max-height: 100%;
            height: calc(100vh - 100px);
        }
    }
}

.pannellumAction {
    .arrow {
        background-color: #ffffff;
        z-index: 1;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        position: absolute;
        bottom: 45%;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            width: 20px;
            height: 4px;
            background: var(--primary);
            opacity: 1;
            border-radius: 30px;
            position: relative;
            top: -5px;
        }

        &:after {
            content: '';
            display: block;
            width: 20px;
            height: 4px;
            background: var(--primary);
            opacity: 1;
            border-radius: 30px;
            position: relative;
            top: 6px;
        }

        &.next {
            right: 30px;

            &:before {
                transform: rotate(45deg);
                right: -12px;
            }

            &:after {
                transform: rotate(-45deg);
                right: 8px;
            }
        }

        &.prev {
            left: 30px;

            &:before {
                transform: rotate(-45deg);
                right: -8px;
            }

            &:after {
                transform: rotate(45deg);
                right: 12px;
            }
        }

        &:hover {
            opacity: 0.7;
        }
    }

    .playPannellum {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 50px;
        height: 50px;
        margin: auto;
        cursor: pointer;
        z-index: 1;

        &:hover {
            opacity: 0.7;
        }
    }
}