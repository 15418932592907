@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

html {
  font-family: "Poppins", sans-serif;

  @media (max-width: 767px) {
    font-size: 12px !important;
  }
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

button:focus,
input:focus,
optgroup:focus,
select:focus,
a:focus,
textarea:focus {
  box-shadow: none !important;
  outline: none;
}

:root {
  --primary: #8f2630;
}

.skipBtn {
  right: 20px;
  bottom: 20px;

  &:hover {
    background-color: var(--primary);
  }
}

.hint {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  transform: scale(1);
  background: #42b649;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
  position: absolute;
  top: 40%;
  left: 0%;
  border: 2px solid rgba(255, 255, 255, 1);
  z-index: 3;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:before {
    display: none;
    background: #2b2c2d;
    padding: 4px 10px;
    font-size: 13px;
    border: 1px solid #dedede;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    content: attr(rel);
    position: absolute;
    width: auto;
    top: 30px;
    white-space: nowrap;
  }

  &:hover {
    &:before {
      display: block;
    }
  }
}

.form-check [type="radio"]:checked,
.form-check [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.form-check [type="radio"]:checked + label,
.form-check [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 34px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #24253a;
  font-size: 14px;
}

.form-check [type="radio"]:checked + label:before,
.form-check [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 100%;
  background: transparent;
}

.form-check [type="radio"]:checked + label:after,
.form-check [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--primary);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-check [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.form-check [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* radiobtn css end */

.chat {
  color: #515c6f;

  .chat-box {
    margin-top: 20px;
  }

  .card-header {
    border-bottom: 1px solid rgba(16, 169, 178, 0.45);

    .card-title {
      font-size: 1.357rem;
      color: #000;
      font-weight: 600;
    }
  }

  .card-body {
    color: #515c6f;
  }
}

.chatSection_blk {
  height: 380px;
  width: 100%;
  overflow-y: auto;
  padding: 15px;

  .chatList {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    width: 100%;

    li {
      word-break: break-word;
      display: flex;
      margin-bottom: 20px;

      .user {
        width: 34px;
        height: 34px;
        display: flex;
        overflow: hidden;
        border-radius: 50%;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
        }
      }

      .content {
        width: calc(100% - 34px);
        padding-left: 10px;
        padding-right: 10px;
        line-height: 1.3;

        .userName {
          font-size: 1rem;
          line-height: 2;
          font-weight: 500;
        }

        .message {
          background-color: #f5f5f5;
          padding: 12px;
          border-radius: 0 10px 10px;
          font-weight: 400;
          letter-spacing: 0;
          display: inline-block;
          color: #515c6f;

          a {
            color: #6a92d7;
            padding: 3px 0;
            display: flex;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .time {
          color: #c7c7c7;
          font-size: 10px;
          line-height: 2;
          text-align: right;
        }
      }

      &.me {
        flex-direction: row-reverse;

        .content {
          text-align: right;

          .userName {
            text-align: right;
          }

          .message {
            border-radius: 10px 0px 10px 10px;
            text-align: left;
            background-color: #ffffff;
            border: 1px solid #a5a9b273;
          }
        }
      }
    }
  }
}

.askaQuestion {
  .chatSection_blk {
    .chatList {
      li {
        flex-direction: column;
        margin-bottom: 0;

        .content {
          width: 100%;
          margin-bottom: 10px;
        }

        .answer {
          .message {
            background-color: #515c6f;
            color: #fff;
          }
        }

        .question {
          text-align: right;

          .message {
            background-color: var(--primary);
            color: #fff;
            border-radius: 10px 0 10px 10px;
          }
        }
      }
    }
  }
}

.rightAction {
  position: absolute;
  right: 0;
  display: block;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 190px;
  display: flex;
  align-items: center;

  @media (max-height: 400px) {
  }

  li {
    margin-bottom: 10px;

    a,
    span,
    button {
      background: #000000;
      color: #fff;
      display: flex;
      width: 100%;
      padding: 8px 14px;
      border-radius: 9px 0 0 9px;
      cursor: pointer;
      text-align: left;
      min-width: 130px;

      @media (max-height: 400px) {
        padding: 5px 14px;
        font-size: 12px;
      }

      &:hover {
        background: #000000b3;
      }
    }

    .social {
      font-size: 20px;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        height: 20px;
      }
    }
  }
}

.leftAction {
  position: absolute;
  left: 0;
  display: block;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 190px;
  display: flex;
  align-items: center;

  @media (max-height: 400px) {
  }

  .backToPrev {
    font-size: 30px;
    color: #fff;
    margin-left: 20px;
    cursor: pointer;
    position: relative;
    transition: all 1;
    -moz-animation: backToPrev 1.5s infinite linear;
    -o-animation: backToPrev 1.5s infinite linear;
    -webkit-animation: backToPrev 1.5s infinite linear;
    animation: backToPrev 1.5s infinite linear;

    &:hover {
      color: var(--primary);
    }
  }
}

@keyframes backToPrev {
  0% {
    left: 0px;
    top: 0px;
  }

  25% {
    left: 7px;
    top: 0px;
  }

  100% {
    left: 0px;
    top: 0px;
  }
}

.model_blk {
  .close {
    top: 15px;
    right: 15px;
  }

  .popup-header {
    h3 {
      font-size: 18px;
      color: #000;
      padding-bottom: 10px;
      font-weight: 600;
    }
  }

  .popup-body {
    max-height: 470px;
    overflow-y: auto;
  }
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid rgb(160, 160, 150);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;

  &--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      > * {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      background: none;

      &:enabled {
        &:hover,
        &:focus {
          background-color: rgb(230, 230, 230);
        }
      }

      &[disabled] {
        background-color: rgb(240, 240, 240);
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;

      &__weekday {
        padding: 0.5em;
      }
    }

    &__weekNumbers {
      font-weight: bold;

      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: rgb(209, 0, 0);
        }

        &--neighboringMonth {
          color: rgb(117, 117, 117);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;

    &:disabled {
      background-color: rgb(240, 240, 240);
    }

    &:enabled {
      &:hover,
      &:focus {
        background-color: rgb(230, 230, 230);
      }
    }

    &--now {
      $bgcolor: lighten(rgb(220, 220, 0), 30%);
      background: $bgcolor;

      &:enabled {
        &:hover,
        &:focus {
          background: lighten($bgcolor, 10%);
        }
      }
    }

    &--hasActive {
      $bgcolor: lighten(rgb(0, 110, 220), 30%);
      background: $bgcolor;

      &:enabled {
        &:hover,
        &:focus {
          background: lighten($bgcolor, 10%);
        }
      }
    }

    &--active {
      $bgcolor: rgb(0, 110, 220);
      background: $bgcolor;
      color: white;

      &:enabled {
        &:hover,
        &:focus {
          background: lighten($bgcolor, 10%);
        }
      }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loadTansection {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: slideInFromLeft 0.6s linear 0s;
  background-color: #000000;
}

body {
  overflow: hidden !important;
}

.myProfile {
  div {
    position: relative;
  }
}
.reqStar {
  color: red;
  position: absolute;
  left: 0;
  top: 0;
}
.reqErrorTxt {
  color: red;
  font-size: 12px;
  top: -8px;
  position: relative;
}

.upload {
  width: 100px;
  display: flex;
  height: 100px;
  position: relative;
  margin: 0 auto;

  .uploadImg {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #38a169;
  }

  .edit {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #38a169;
    background-position: center;
    background-image: url(../../images/edit.png);
    background-size: 14px;
    background-repeat: no-repeat;

    input {
      opacity: 0;
    }
  }
}

.bg-cover {
  background-size: cover !important;
}

.bg-fixed {
  background-attachment: fixed !important;
}

@-webkit-keyframes bounce {
  0% {
    bottom: 1em;
  }

  50% {
    bottom: 1.2em;
  }

  70% {
    bottom: 1.3em;
  }

  100% {
    bottom: 1em;
  }
}

@-moz-keyframes bounce {
  0% {
    bottom: 1em;
  }

  50% {
    bottom: 1.2em;
  }

  70% {
    bottom: 1.3em;
  }

  100% {
    bottom: 1em;
  }
}

@-o-keyframes bounce {
  0% {
    bottom: 1em;
  }

  50% {
    bottom: 1.2em;
  }

  70% {
    bottom: 1.3em;
  }

  100% {
    bottom: 1em;
  }
}

@-ms-keyframes bounce {
  0% {
    bottom: 1em;
  }

  50% {
    bottom: 1.2em;
  }

  70% {
    bottom: 1.3em;
  }

  100% {
    bottom: 1em;
  }
}

@keyframes bounce {
  0% {
    bottom: 1em;
  }

  50% {
    bottom: 1.2em;
  }

  70% {
    bottom: 1.3em;
  }

  100% {
    bottom: 1em;
  }
}

.btn-submit {
  font-weight: 600;
  padding: 5px 15px;
  background: var(--primary);
  font-size: 1.07rem;
  border-radius: 20px;
  color: #fff;
  display: inline-flex;
  align-items: center;

  &[disabled],
  &:disabled {
    background-color: #ddd !important;
  }

  .coin {
    margin-left: 10px;
  }
}

.btn-cancel {
  font-weight: 600;
  padding: 5px 15px;
  background: #515c6f;
  font-size: 1.07rem;
  border-radius: 20px;
  color: #fff;
  display: inline-flex;
  align-items: center;

  &[disabled],
  &:disabled {
    background-color: #ddd !important;
  }

  .coin {
    margin-left: 10px;
  }
}

.btn-back {
  font-weight: 600;
  padding: 5px 15px;
  background: #ffffff;
  font-size: 1.07rem;
  border-radius: 20px;
  color: var(--primary);
  display: inline-flex;
  align-items: center;

  &[disabled],
  &:disabled {
    background-color: #ddd !important;
  }

  &:hover {
    opacity: 0.8;
  }
}

.loader {
  min-height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Empty {
  min-height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .nodata {
    img {
      max-height: 100px;
    }
  }
}

.feedbackTextarea {
  .msg-input {
    min-height: 7rem;
  }
}

.react-tel-input {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

.agendaPop {
  &.model_blk {
    .popup-body {
      max-height: 570px;
    }
  }

  iframe {
    min-height: 400px;
    width: 100%;
  }
}

.portrait {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999999999999999999999;
  background: #972b36;
  display: none;
  top: 0;
  left: 0;
  overflow: hidden;

  .overlayM {
    background-color: #972b36;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconContainer {
      display: flex;
      flex-direction: column;

      .phone {
        box-sizing: border-box;
        justify-content: center;
        margin-bottom: 10px;

        img {
          height: 40px;
        }
      }

      p {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin: 0;
        font-size: 15px;
        color: #fff !important;
      }
    }
  }
}

@media only screen and (max-width: 1020px) {
  .portrait,
  .overlayM {
    display: block;
  }
}

.Toaster__message {
  .Toaster__alert {
    border-radius: 3px;
    padding: 5px 11px;
    padding-right: 30px;

    .Toaster__alert_close {
      padding: 0 5px;
    }
  }
}

.__floater {
  .__floater__body {
    .react-joyride__tooltip {
      border-radius: 6px;
    }
  }
}

.react-joyride__overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

video::-webkit-media-controls {
  display: none !important;
}

.srcnLoader {
  position: fixed;
  z-index: 99999;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 60px;
}

.react-tel-input {
  margin-top: 0.25rem;

  .form-control {
    width: 100% !important;
  }
}

.footer {
  transform: translateX(0);
  transition: all 0.5s;

  &:hover {
    transform: translateY(-5px);
  }
}

.switch {
  .switch__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: #962b35;
    border-radius: 1rem;

    transition: background 0.2s;
    cursor: pointer;

    &::before {
      content: "";

      position: absolute;
      left: 0.2rem;
      top: 50%;
      transform: translateY(-50%);

      height: 1.6rem;
      width: 1.6rem;
      border-radius: 1.6rem;

      background: #fff;
      transition: transform 0.2s, width 0.2s, background 0.2s;
    }
  }

  .switch__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:not(:checked):active + .switch__slider::before {
      width: 2.4rem;
    }

    &:checked {
      + .switch__slider::before {
        transform: translate(1.8rem, -50%);
      }

      &:active + .switch__slider::before {
        transform: translate(1rem, -50%);
        width: 2.4rem;
      }
    }
  }
}
