.booth_blk {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .intelliBooth {
        height: 100%;
        width: 100%;
        position: absolute;

    }
}

.exhibitionFloorPage {
    background: #9c9994;

    .boothContainer {
        position: absolute;
        width: 1360px;
        height: 810px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;

        @media (width:1920px) and (min-height:1075px) {
            width: 130vh;
            height: 75vh;
        }

        @media (width:1920px) and (max-height:1074px) {
            width: 1350px;
            height: 825px;
        }

        @media (width:1680px) and (min-height:1075px) {
            width: 130vh;
            height: 75vh;
        }

        @media (width:1680px) and (max-height:1074px) {
            width: 1190px;
            height: 715px;
        }

        @media (max-width:1600px) {
            width: 1140px;
            height: 680px;
        }

        @media (width:1600px) and (min-height:910px) {
            width: 130vh;
            height: 75vh;
        }

        @media (width:1600px) and (max-height:909px) {
            width: 1150px;
            height: 685px;
        }

        @media (width:1440px) and (min-height:810px) {
            width: 130vh;
            height: 75vh;
        }

        @media (width:1440px) and (max-height:809px) {
            width: 1050px;
            height: 624px;
        }

        @media (width:1400px) and (min-height:790px) {
            width: 130vh;
            height: 75vh;
        }

        @media (width:1400px) and (max-height:789px) {
            width: 1000px;
            height: 610px;
        }

        @media (width:1366px) and (min-height:768px) {
            width: 130vh;
            height: 75vh;
        }

        @media (width:1366px) and (max-height:767px) {
            width: 930px;
            height: 570px;
        }

        @media (width:1280px) and (min-height:719px) {
            width: 130vh;
            height: 75vh;
        }

        @media (width:1280px) and (max-height:718px) {
            width: 930px;
            height: 570px;
        }

        @media (max-width:1240px) {
            width: 870px;
            height: 540px;
        }


        @media (width:1240px) and (min-height:699px) {
            width: 126vh;
            height: 77vh;
        }

        @media (max-width:1024px) {
            width: 126vh;
            height: 77vh;
        }

        &.boothFloor_8 {
            .boothPoint_6 {
                top: 15%;
                left: 20%;
            }

            .boothPoint_7 {
                top: 15%;
                left: 49%;
            }

            .boothPoint_8 {
                top: 15%;
                left: 77%;
            }

            .boothPoint_3 {
                top: 43%;
                left: 13%;
            }

            .boothPoint_4 {
                top: 43%;
                left: 49%;
            }

            .boothPoint_5 {
                top: 43%;
                left: 85%;
            }

            .boothPoint_1 {
                top: 80%;
                left: 20%;
            }

            .boothPoint_2 {
                top: 80%;
                left: 75%;
            }
        }

        &.boothFloor_5 {
            .boothPoint_3 {
                top: 32%;
                left: 12%;
            }

            .boothPoint_4 {
                top: 32%;
                left: 49%;
            }

            .boothPoint_5 {
                top: 32%;
                left: 87%;
            }

            .boothPoint_1 {
                top: 72%;
                left: 21%;
            }

            .boothPoint_2 {
                top: 72%;
                left: 76%;
            }
        }

        &.boothFloor_4 {
            .boothPoint_2 {
                top: 36.5%;
                left: 12%;
            }

            .boothPoint_3 {
                top: 32%;
                left: 49%;
            }

            .boothPoint_4 {
                top: 36.5%;
                left: 85%;
            }

            .boothPoint_1 {
                top: 72%;
                left: 49%;
            }

        }

        &.boothFloor_3 {
            .boothPoint_1 {
                top: 70%;
                left: 50%;
            }

            .boothPoint_2 {
                top: 32%;
                left: 28%;
            }
            .boothPoint_3 {
                top: 32%;
                right: 28%;
                left: auto;
            }
        }

        &.boothFloor_2 {
            .boothPoint_1 {
                top: 51%;
                left: 20%;
            }

            .boothPoint_2 {
                top: 51%;
                left: 77%;
            }

        }

        .hint {
            &:before {
                display: none !important;
            }
        }
    }
}

.exhibitionFloorSlider {
    .exhibitionFloorImg {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slick-arrow {
        background-color: #ffffff;
        z-index: 1;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;

        &:hover {
            background-color: #ffffff;
        }

        &:before {
            content: '';
            display: block;
            width: 20px;
            height: 4px;
            background: var(--primary);
            opacity: 1;
            border-radius: 30px;
            left: 25%;
            position: relative;
            top: -3px;
        }

        &:after {
            content: '';
            display: block;
            width: 20px;
            height: 4px;
            background: var(--primary);
            opacity: 1;
            border-radius: 30px;
            left: 25%;
            position: relative;
            top: 5px;
        }

        &.slick-disabled {
            display: none !important;
        }


        &.slick-prev {
            left: 50px;

            &:before {
                transform: rotate(-45deg);
            }

            &:after {
                transform: rotate(45deg);
            }
        }

        &.slick-next {
            right: 50px;

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }

}