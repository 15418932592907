.gameList {
  .gameLisinUl {
    display: flex;

    li {
      width: 100%;
      padding: 0 15px;

      @media (max-width: 1100px) {
        padding: 0 8px;
      }

      .gameCard {
        padding-top: 35px;
        border-radius: 50px 50px 50px 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        transform: scale(1);
        transition: all 0.4s;

        &:hover {
          transform: scale(1.06);

          .gameCont {
            transform: translateY(-10px);
          }
        }

        @media (max-width: 825px) {
          padding-top: 25px;
          border-radius: 20px 20px 20px 0px;
        }

        @media (max-width: 670px) {
          padding-top: 15px;
        }

        .gameCardImg {
          height: 18vw;

          @media (max-width: 670px) {
            height: 17vw;
          }

          img {
            margin: 0 auto;
            width: 100%;
          }
        }

        .gameCont {
          padding: 0 32px 25px;
          transform: translateY(0px);
          transition: all 0.6s;
          transition-delay: 0.5s;


          @media (max-width: 1370px) {
            padding: 0 20px 25px;
          }

          @media (max-width: 825px) {
            padding: 0 10px 15px;
          }

          h3 {
            color: #fff;
            font-size: 1.5vw;
            font-weight: bold;
            letter-spacing: 0.28px;

            @media (max-width: 1370px) {
              font-size: 1.3vw;
            }

          }

          p {
            color: #fff;
            font-size: 1vw;

            @media (max-width: 1370px) {
              font-size: 0.9vw;
            }

            @media (max-width: 1100px) {
              font-size: 0.88vw;

              img {
                height: 1rem;
              }
            }

            @media (max-width: 825px) {
              font-size: 0.85vw;
            }

            @media (max-width: 670px) {
              font-size: 0.78vw;

              img {
                height: 0.7rem;
                margin-right: 3px;
                margin-left: 3px;
              }
            }

            letter-spacing: 0.21px;
          }
        }
      }

      &:nth-child(1) {
        .gameCard {
          background-color: #eb933b;
        }
      }

      &:nth-child(2) {
        .gameCard {
          background-color: #92d239;
        }
      }

      &:nth-child(3) {
        .gameCard {
          background-color: #0059a6;
        }
      }

      &:nth-child(4) {
        .gameCard {
          background-color: #509eff;
        }
      }

      &:nth-child(5) {
        .gameCard {
          background-color: #51c7db;
        }
      }
    }
  }
}

.z-1 {
  z-index: 1;
}

.coinCount {
  display: inline-flex;
  position: relative;

  .coinImgSec {
    height: 52px;
    width: 52px;
    padding: 7px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;

    @media (max-width: 820px) {
      height: 40px;
      width: 40px;
      padding: 0;
    }

    img {}
  }

  .coinCount {
    min-width: 150px;
    height: 52px;
    padding: 0 20px 0 60px;
    background-color: #fe7800;
    color: #fff;
    border-radius: 47px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    letter-spacing: 0.3px;
    font-weight: bold;

    @media (max-width: 820px) {
      min-width: 110px;
      height: 40px;
      padding: 0 20px 0 50px;
      border-radius: 47px;
      font-size: 20px;
    }
  }
}

.gamePointCount {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 111;
  display: flex;
  align-items: center;

  .infoCoins {
    font-size: 25px;
    cursor: pointer;
    color: #fe7800;
    margin-left: 3px;
    z-index: 1;
    box-shadow: 0px 0px 5px 0px #fff;
    border-radius: 50%;
    background: #fff;
    transform: scale(1);
    transition: all 0.5s;

    &:hover {
      transform: scale(1.02);
    }
  }

  // .redeemAction {
  //   position: absolute;
  //   right: 0;
  //   bottom: -10px;
  //   color: #fff;
  // }
}

@keyframes spinner {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }

  50% {
    transform: rotate3d(0, 1, 0, 180deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

.playGameSec {
  iframe {
    position: absolute;
    width: 100%;
    height: calc(100% - 80px);
    left: 0;
    right: 0;
    bottom: 0;
  }
}